.employees {
  background-color: var(--color-bg-primary);
}

.selectDate {
  position: relative;
  margin-bottom: 1.5rem;
  .form-label {
    position: absolute;
    top: -20px;
    margin: 0 14px;
    background-color: var(--background-color-secondary) !important;
    padding: 2px 15px;
    border-radius: 5px;
    border: none;
    color: #ffffff;
    z-index: 9;
  }
  .react-datepicker__input-container {
    input {
      background-color: var(--background-color-third);
      width: 100%;
      border: 1px solid rgb(146, 143, 143);
      border-radius: 5px;
      padding: 12px;
    }
  }
}
