.appbar {
  background-color: var(--color-bg-primary);
  .profileInfo {
    display: flex;
    flex-direction: row;
    text-align: center;
    height: 50px;
    color: var(--color-text-primary);
  }

  .navbar-nav {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
  .mybtn-primary {
    color: var(--color-text-primary);
    background-color: var(--background-btn-secondary);
  }
  .Info {
    display: flex;
    flex-direction: column;
  }
  .ava {
    img {
      border-radius: 50px;
      width: 50px;
      height: 50px;
      margin: 0 13px;
    }
  }
  .name {
    margin: 0 !important;
  }

  .dropdown-toggle:after {
    display: none !important;
  }
  .icon {
    margin-inline-start: 5px;
  }
}

.toggleSidebar {
  display: none;
}

.collapsedSidebar {
  display: block;
}

@media (max-width: 767.98px) {
  .toggleSidebar {
    display: block;
  }

  .collapsedSidebar {
    display: none;
  }
}

[dir="rtl"] {
  .me-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }
}

html[lang="ar"] {
  .dropdown-item {
    text-align: right;
  }
}

html[lang="en"] {
  .dropdown-menu[data-bs-popper] {
    left: -46px;
  }
}

.dropdown-item {
  svg {
    margin-inline-end: 10px;
  }
}
