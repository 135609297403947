.selectBox {
  position: relative;
  label {
    position: absolute;
    margin-inline-start: 15px;
    top: -20px;
 
    padding: 2px 15px;
    border-radius: 5px;
    border: none;
    color: #ffffff;
  }
}
