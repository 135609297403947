.login {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Tajawal", sans-serif;
  background: linear-gradient(to right bottom, #d8f1ff, #8bb9ff);
  // background: linear-gradient(to right bottom, #009cf750, #0d6dfd93),
  //   url("../../assets//images/pexels-edward-jenner-4252524.webp");
  // background-size: cover;
  // background-repeat: no-repeat;
  // position: relative;

  .mybtn-primary {
    background-color: transparent !important;
  }

  .langButton {
    position: absolute;
    top: 30px;
    right: 30px;
    border: none;
    border-radius: 5px;
    background-color: #e3f5ff !important;

    img {
      margin-inline-start: 10px;
    }
  }


  .wrapper {
    min-width: 260px;
    width: 500px;
    margin: 20px;

    .logo {
      box-shadow: 0 0 28px 0 rgba(82, 63, 105, 0.05);
      border-radius: 50%;
      margin-bottom: 40px;
      text-align: center;
      display: block;
      width: 180px;
      height: 180px;
      margin: 0 auto;
      margin-bottom: 50px;

      img {
        border-radius: 50%;
      }
    }

    form {
      width: 100%;
      box-shadow: 0 0 28px 0 rgba(82, 63, 105, 0.05);
      border-radius: 10px;
      padding: 20px;
      background-color: #fff;
      color: #646464;

      h2 {
        // font-weight: bold;
        position: relative;
        color: #646464;
      }

      .fm-group {
        position: relative;

        .form-label {
          position: absolute;
          top: 12px;
          margin-inline-start: 15px;
          transition: all 0.3s ease-in-out;
        }

        input {
          color: #495057;
          border: none;
          padding: 10px 15px;
          border: 1px solid #0d6efd8a;
          background-color: transparent !important;
        }

        input:focus + .form-label,
        input:not(:placeholder-shown) + .form-label {
          top: -18px;
          background-color: #4690ff !important;
          padding: 3px 8px;
          border-radius: 10px;
          box-shadow: 0 0 28px 0 rgba(82, 63, 105, 0.05);
        }
      }

      .btn-login {
        // background: linear-gradient(to right bottom, #009ef7, #0d6efd);
        background-color: #4690ff;
        color: #fff;
        border-color: transparent;
        border-radius: 10px;
        width: 100%;
        padding: 7px 0;
        transition: all 0.3s ease-in-out;
        font-weight: bold;
        box-shadow: 0px 11px 18px -8px #0d6efd8a;
      }
    }
  }
}

html[lang="ar"] {
  .langButton {
    right: auto;
    left: 30px;
  }
}


