.layout {
  // display: flex;
  // height: 100vh;
  display: flex;
  position: relative;

  .pro-sidebar {
    margin-bottom: 20px;
  }

  .content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    transition: all 0.3s ease-in-out;
  }
}

[dir="ltr"] {
  .content {
    margin-left: 270px;
  }
}

[dir="rtl"] {
  .content {
    margin-right: 270px;
  }

  .pro-sidebar.collapsed + .content {
    margin-right: 80px;
    margin-left: auto;
  }
}

.pro-sidebar {
  transition: all 0.3s ease-in-out;
}

.pro-sidebar.collapsed + .content {
  margin-left: 80px;
  transition: all 0.3s ease-in-out;
}

@media (max-width: 768px) {
  .layout {
    .pro-sidebar {
      height: 100vh !important;
    }

    .content {
      width: 100%;
      margin: 0;
    }
  }
}
