/* css var */

body[data-theme="light"] {
  --color-text-primary: #131616;
  --color-text-secondary: #0588a6;
  --color-bg-primary: #ffff;
  --color-bg-secondary: #e3f5ff;
  --color-bg-third: #0588a6;
  --color-text-fourth: #0588a6;
  --color-text-hover: #131616;
  --box-shadow: 0 0 28px 0 rgba(82, 63, 105, 0.05);
  --box-shadow-btn: 0px 11px 18px -8px #0d6efd8a;
  --background-btn: rgba(147, 208, 243, 0.243);
  --background-btn-secondary: rgba(147, 208, 243, 0.243);
  --font-family: "Tajawal", sans-serif;
  font-family: var(--font-family);
  --background-color-primary: rgba(147, 208, 243, 0.243);
  --background-color-secondary: #89b9ff;
  --background-color-third: white;
  ----background-hover-btn: #0588a6;
  ----background-hover-secBtn: #0588a6;

  font-family: "Tajawal", sans-serif;
  background-color: white;
  ----background-active-primary: #0588a6;
  ----background-active-secondary: #eeeeee;
  color: var(--color-text-primary);
}
body[data-theme="dark"] {
  --color-text-primary: #f2f5f7;
  --color-text-secondary: #0588a6;
  --color-text-fourth: #3cc8e7;
  --color-text-hover: white;
  --color-bg-primary: #434343;
  --color-bg-secondary: #bbbb;
  --color-bg-third: #0588a6;
  --box-shadow: 0 0 28px 0 #333333;
  --box-shadow-btn: 0px 11px 18px -8px #0d6efd8a;
  --background-btn: #6e6d6d;
  --background-btn-secondary: #3a3a3a;
  ----background-hover-btn: rgba(93, 94, 95, 0.592);
  ----background-hover-secBtn: rgba(223, 227, 231, 0.592);

  background-color: #3a3a3a;
  --background-color-primary: rgba(117, 135, 146, 0.243);
  --background-color-secondary: #3a3a3a;
  --background-color-third: rgb(207, 206, 206);
  ----background-active-primary: #aba9a9;
  ----background-active-secondary: #aba9a9;
  --font-family: "Tajawal", sans-serif;
  font-family: var(--font-family);
  color: var(--color-text-primary);
}
/* body */

.form-select {
  background-color: var(--background-color-third);
}
.selectBox label {
  background-color: var(--background-color-secondary);
}

body[data-theme="dark"].boxCharts h2 {
  color: #0588a6;
}
body[data-theme="dark"] .modal-content {
  background: #434343;
}
body[data-theme="dark"] .qCXoK:hover {
  background: #aba9a9;
  color: white;
}
body[data-theme="dark"] .data-table-extensions-action > button:hover {
  background-color: #39394e;
}
body[data-theme="dark"]
  .data-table-extensions
  > .data-table-extensions-action
  > button::after {
  background-color: #39394e;
}
.apexcharts-menu.apexcharts-menu-open {
  background-color: var(--color-bg-primary);
}
.dropdown-menu {
  background-color: var(--color-bg-primary);
}
.dropdown-item {
  color: var(--color-text-primary);
}
.apexcharts-theme-light .apexcharts-menu-icon:hover svg {
  fill: #0588a6 !important;
}
.apexcharts-theme-light .apexcharts-reset-icon:hover svg {
  fill: #0588a6 !important;
}
.apexcharts-theme-light .apexcharts-zoomout-icon:hover svg {
  fill: #0588a6 !important;
}
.apexcharts-theme-light .apexcharts-zoomin-icon:hover svg {
  fill: #0588a6 !important;
}
/* Globals css classes */
.box {
  margin: 20px;
  padding: 4px 10px;
  box-shadow: var(--box-shadow);
  background-color: var(--color-bg-primary);
}

.mie {
  margin-inline-end: 10px;
}

/* Global btns */
.btn-primary {
  background-color: var(--background-btn);
  /* box-shadow: var(--box-shadow-btn); */
  color: var(--color-text-fourth);
  border: none;
}

.btn-primary:hover {
  background-color: var(----background-hover-btn);
}

.mybtn-primary {
  border: none;
  border-radius: 5px;
  margin: 0 5px;
  background-color: var(--background-btn-secondary);
  /* box-shadow: var(--box-shadow-btn); */

  padding: 10px;
}

.mybtn-primary svg {
  color: #0588a6;
}

.btn-danger {
  border: none;
  color: #ff0019e8;
  background-color: #ffdbde;
}

.btn-danger:hover {
  color: #fff;
  background-color: #ff4f5e;
}

.btn-success {
  border: none;
  color: #198754;
  background-color: #1987543b;
}

.btn-success:hover {
  color: #fff;
  background-color: #198754;
}

.btn-success svg {
  margin-inline-end: 10px;
}

.btn-secondary {
  border: none;
  color: #6c757d;
  background-color: #ededed;
}

.btn-secondary:hover {
  background-color: #9a9393;
}

.rotate {
  transform: rotate(180deg);
}

/* Toastify__toast */
html[lang="ar"] .Toastify__toast {
  direction: rtl;
}

html[lang="ar"] .modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem auto -0.5rem -0.5rem;
}

html[lang="ar"] .form-control.is-invalid,
html[lang="ar"] .was-validated .form-control:invalid {
  background-position: right calc(24em + 0.1875rem) center;
}

html[lang="ar"] .form-floating > .form-control-plaintext ~ label,
html[lang="ar"] .form-floating > .form-control:focus ~ label,
html[lang="ar"] .form-floating > .form-control:not(:placeholder-shown) ~ label,
html[lang="ar"] .form-floating > .form-select ~ label {
  opacity: 1;
  transform: scale(0.85) translateY(-0.9rem) translateX(4.149999999999999rem);
}

.sectionTitle {
  padding: 0 20px;
}

.sectionTitle .title {
  font-size: 17px;
  color: #0588a6;
  border-radius: 7px;
  font-weight: bolder;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.sectionTitle .title svg {
  margin-inline-start: 10px;
  margin-inline-end: 10px;
}

html[lang="ar"] .form-switch {
  direction: ltr;
  padding-left: 3.5em;
}

.cQLlaq,
.cEMZEX {
  z-index: 0 !important;
}

/* datapicker styling */
.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  background-color: white;
}
.react-datepicker {
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  font-family: var(--font-family);
  padding: 11px 20px;
}
.react-datepicker__navigation-icon--previous {
  right: 3px;
  top: 4px;
}
.react-datepicker__navigation-icon--next {
  left: 2px;
  top: 4px;
}
.react-datepicker__navigation-icon::before {
  border-color: var(----background-active-secondary);
}
.react-datepicker__navigation {
  background: var(--background-color-secondary);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 15px;
  top: 3%;
}

.react-datepicker__navigation--previous {
  left: 11px;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 97px;
}

.react-datepicker__header {
  background-color: transparent !important;
}

.react-datepicker__day {
  /* padding: 5px 10px;
  font-size: 15px; */
}

.react-datepicker__day-name {
  font-weight: bold;
  /* padding: 0px 10px; */
}

.react-datepicker__day-name {
  visibility: hidden;
}
.react-datepicker__day-name::first-letter {
  visibility: visible;
}

.react-datepicker__day:hover {
  border-radius: 20px;
  color: var(--color-text-primary);
  background: var(--background-color-secondary);
}

#selectoOptions {
  color: black !important;
}
.nav-pills {
  --bs-nav-pills-link-active-bg: var(----background-active-primary);
}
.nav {
  --bs-nav-link-color: var(--color-text-primary);
  --bs-nav-link-hover-color: var(----background-hover-secBtn);
}
[dir="rtl"] .page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

[dir="rtl"] .page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}

.react-confirm-alert-overlay {
  z-index: 999999999999999999;
}
.form-control {
  background-color: var(--background-color-third);
}
textarea.form-control {
  background-color: var(--background-color-third);
}

/* table */
body[data-theme="dark"] .rdt_Pagination {
  color: #fff !important;
}

body[data-theme="dark"] .rdt_Pagination button:disabled svg {
  fill: rgba(255, 255, 255, 0.397);
  cursor: not-allowed;
}
body[data-theme="dark"] .rdt_Pagination button svg {
  fill: #fff;
}

.inputFile label {
  width: 100%;
  background: var(--background-color-third);
  color: #333;
  padding: 11px;
  border-radius: 5px;
  text-align: center;
  border: 1px dashed #7a7272;
  cursor: pointer;
}

.inputFile input {
  display: none;
}

.navbar-nav .dropdown-menu {
  position: absolute !important;
}

.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__time-name {
  line-height: 3rem;
  width: 13%;
}

.react-datepicker__header {
  border-bottom: 1px solid #31313129;
}

.react-datepicker__time-container {
  border-left: 1px solid #31313129;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.react-datepicker-time__header {
  margin-top: 18px;
}
