.fm-group {
  position: relative;
  color: black;

  .form-label {
    position: absolute;
    top: 12px;
    margin-inline-start: 15px;
    transition: all 0.3s ease-in-out;
  }

  input {
    color: black;

    border: none;
    background-color: var(--background-color-third);
    padding: 10px 15px;
    border: 1px solid rgb(146, 143, 143);
  }

  input:focus + .form-label,
  input:not(:placeholder-shown) + .form-label {
    top: -18px;
    background-color: var(--background-color-secondary);
    padding: 2px 15px;
    border-radius: 5px;
    border: none;
    color: #ffffff;
  }
}

// .form-floating > .form-control-plaintext ~ label,
// .form-floating > .form-control:focus ~ label,
// .form-floating > .form-control:not(:placeholder-shown) ~ label,
// .form-floating > .form-select ~ label {
//   top: -18px;
//   background-color: #89b9ff !important;
//   padding: 2px 15px;
//   border-radius: 5px;
//   border: none;
//   color: #ffffff;
// }
