.boxCharts {
  margin-bottom: 15px;
  .apexcharts-tooltip.apexcharts-theme-light {
    background-color: var(--color-bg-primary);
  }
  .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
    background-color: var(--color-bg-primary);
  }
  // flex-grow: 1;
  // min-width: auto;
  // width: 400px;
  // max-width: 400px;
  // background-color: #fff;
  // margin: 15px;
  box-shadow: var(--box-shadow);
  background-color: var(--color-bg-primary);
  padding: 10px;
  border-radius: 15px;

  h2 {
    margin: 18px 0 30px 0;
    text-align: center;
    color: #0588a6;
    text-shadow: 1px 1px 1px#ddd;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      background: var(--background-color-primary);
      padding: 5px;
      border-radius: 5px;
    }
  }
}
