.infoReports {
  .infoReportsHeading {
    display: flex;
    align-items: center;
    color: var(--color-bg-third);
    margin: 20px 5px;
    svg {
      margin-inline-end: 8px;
      width: 35px;
      height: 35px;
    }
  }

  .infoReportsBody {
    height: 550px;
  }

  .infoReportsDetails {
    .details {
      box-shadow: var(--box-shadow);
      border-radius: 10px;
      padding: 30px 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      svg {
        margin-bottom: 20px;
        width: 30px;
        height: 30px;
        color: var(--color-bg-third);
      }
      h4 {
        margin: 0;
      }
    }
  }

  .comments {
    h3 {
      color: var(--color-bg-third);
      margin-bottom: 30px;
      svg {
        margin-inline-end: 10px;
      }
    }
    .commentDetails {
      position: relative;
      box-shadow: var(--box-shadow);
      padding: 20px;
      margin-bottom: 30px;
      .commentInfo {
        display: flex;

        .info {
          svg {
            margin-inline-end: 6px;
            color: var(--color-bg-third);
          }
          .name {
            margin: 0;
            font-weight: bold;
            font-size: 21px;
          }
          .email {
            // display: block;
            margin-inline-end: 10px;
            color: #a1a5b7;
          }

          .date {
            color: #a1a5b7;
          }
        }

        img {
          border-radius: 50%;
          margin-inline-end: 18px;
        }
      }

      // .deleteComment {
      //   position: absolute;
      //   top: 10px;
      //   right: 10px;
      // }
    }
    .comment {
      margin-top: 40px;
    }

    .mySwiper .swiper-slide {
      opacity: 1 !important;
    }
  }

  .addComment {
    padding: 20px;
    box-shadow: var(--box-shadow);
    border-radius: 10px;

    svg {
      margin-inline-end: 8px;
    }
  }
}

.swiper {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.mySwiper2 {
  height: 80%;
  width: 100%;
}

.mySwiper {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}

.mySwiper .swiper-slide {
  // width: 25%;
  // height: 100%;
  opacity: 0.4;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper-slide .topImages {
  display: block;
  object-fit: contain;
  margin: 0 auto;
  width: 100%;
}

.videoBK {
  background-color: #ddd;
  width: 100%;
  height: 100%;
  color: rgb(33, 33, 33);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

@media (max-width: 768px) {
  video {
    width: 100%;
  }
}
