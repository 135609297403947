.mycard {
  margin-bottom: 60px;
  .card {
    border: none !important;
    margin: 12px !important;
    background-color: var(--color-bg-primary);
  }
  .cardIcon {
    margin-inline-end: 5px;
  }
  .Icons {
    min-width: 100px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  .cardContent {
    display: flex;
    justify-content: space-between;
  }

  .imageCompany {
    border-radius: 50%;
    margin-top: -50px;
  }
}
