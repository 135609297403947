$sidebar-bg-color: var(--color-bg-primary);
$icon-bg-color: var(--background-btn-secondary);
$icon-size: 35px !default;

@import "~react-pro-sidebar/dist/scss/styles.scss";

.pro-sidebar {
  box-shadow: var(--box-shadow);
  position: fixed;
  height: 100vh !important;

  .logoSmall {
    width: 100%;
  }

  .pro-menu.circle .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
    border-radius: 50%;
  }

  svg {
    color: var(--color-bg-third);
  }

  .pro-menu .pro-menu-item > .pro-inner-item:hover {
    color: var(--color-text-secondary);
  }
}

.pro-sidebar .pro-menu .pro-menu-item.active {
  color: var(--color-text-secondary);
  background: var(----background-active-secondary);
}

// .pro-menu-item a.active {
//   color: #009ef7; /* put any color you want */
//   font-weight: 500;
// }

// .pro-sidebar .pro-item-content a! > a {
//   color: red;
// }

// .pro-sidebar .pro-menu a.active:before {
//   color: #009ef7;
//   background: #e3f5ff69;
//   z-index: 0;
// }
