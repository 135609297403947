.home {
  .homeBgColor {
    background-color: var(--color-bg-primary);
  }
  .apexcharts-xaxistooltip {
    background-color: var(--color-bg-primary);
    color: var(--color-text-primary);
  }

  .homeNoty {
    background-color: var(--color-bg-primary);
    .homeNotyTitle {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: #0588a6;
      border-bottom: 1px solid rgba(230, 230, 230, 0.429);
      padding-bottom: 15px;
      font-weight: bold;
      text-shadow: 1px 1px 1px #ddd;
      margin-bottom: 30px;

      .icon {
        background-color: var(--background-color-primary);
        padding: 4px;
        border-radius: 5px;
      }
    }
    .homeNotyBody {
      table {
        color: var(--color-text-primary);

        &:hover {
          --bs-table-hover-color: var(--color-text-hover);
        }

        tbody {
          tr {
            td {
              padding: 20px 0 !important;
              img {
                border-radius: 50px;
              }
            }
          }
        }
      }
    }
  }

  .reports {
    background-color: var(--color-bg-primary);
    .reportsHead {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: #0588a6;
      border-bottom: 1px solid rgba(242, 242, 242, 0.931);
      padding-bottom: 15px;
      font-weight: bold;
      text-shadow: 1px 1px 1px #ddd;
      margin-bottom: 30px;

      .icon {
        background-color: var(--background-color-primary);
        padding: 4px;
        border-radius: 5px;
      }
    }
  }
}

// charts
body[data-theme="dark"] .apexcharts-text tspan {
  fill: #fff;
}
