.reports {
  .filterBox {
    color: #0588a6;
    border-radius: 7px;
    background-color: var(--color-bg-primary);
  }

  .react-datepicker__input-container input {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #ddd;
    padding: 6.5px;
  }

  .css-g1d714-ValueContainer div {
    width: auto !important;
  }
}

.react-datepicker {
  width: 100%;
}

.react-datepicker__month-container {
  @media (max-width: 425px) {
    width: 80%;
  }
}

.react-datepicker__time-container {
  @media (max-width: 425px) {
    width: 20% !important;
  }
}

.dropdownSelect {
  padding: 4px;
  margin: 0 4px;
  border: 1px solid #ddd;
  border-radius: 3px;
}

.btnSelectDate {
  border: 1px solid #dddddd5e;
  padding: 0px 10px;
  border-radius: 5px;
  background: #dddddd2b;
  color: #216ba5;
  font-weight: bold;
}

.react-datepicker__day--selected {
  border-radius: 20px;
  color: #fff;
  background: #89b9ff;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list {
  @media (max-width: 425px) {
    width: 57px !important;
  }
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  padding: 0 !important;
}
